var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sidebar",class:{ 'sidebar-transparent': _vm.pageOptions.pageSidebarTransparent },attrs:{"id":"sidebar"}},[_c('vue-custom-scrollbar',{staticClass:"height-full"},[_c('sidebar-nav',{ref:"sidebarNav",attrs:{"scrollTop":_vm.scrollTop},on:{"showFloatSubmenu":_vm.handleShowFloatSubmenu,"hideFloatSubmenu":_vm.handleHideFloatSubmenu}})],1)],1),_c('div',{staticClass:"sidebar-bg"}),_c('div',{staticClass:"mobile-click",on:{"click":_vm.dismissSidebar}}),(this.pageOptions.pageSidebarMinified)?_c('div',{staticClass:"float-sub-menu-container",class:{ 
			'd-none': !this.floatSubmenu
		},style:({
			top: this.floatSubmenuTop,
			left: this.floatSubmenuLeft,
			bottom: this.floatSubmenuBottom,
			right: this.floatSubmenuRight
		}),on:{"mouseover":_vm.clearHideFloatSubmenu,"mouseleave":_vm.handleHideFloatSubmenu}},[_c('div',{staticClass:"float-sub-menu-arrow",style:({
			top: this.floatSubmenuArrowTop,
			bottom: this.floatSubmenuArrowBottom,
		})}),_c('div',{staticClass:"float-sub-menu-line",style:({
			top: this.floatSubmenuLineTop,
			bottom: this.floatSubmenuLineBottom,
		})}),_c('ul',{staticClass:"float-sub-menu"},[_vm._l((this.floatSubmenuMenu),function(menu){return [_c('sidebar-nav-list',{key:menu.path,ref:"sidebarNavList",refInFor:true,attrs:{"menu":menu,"status":menu.status},on:{"calcFloatSubmenu":_vm.handleCalcFloatSubmenu}})]})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }