<template>
	<div>
		<!-- begin #header -->
		<div id="header" class="header navbar-default">
			<div class="d-flex">
				<div class="header-left">
					<button type="button" class="btn btn-danger">슈퍼어드민</button>
				</div>
				<div class="header-right">
					<div class="row">
						<!-- begin col-3 -->
						<div class="col-xl-2 col-md-4">
							<div class="widget widget-stats panel-widget">
								<div class="stats-icon icon-pink"><i class="fas fa-user"></i></div>
								<div class="stats-info">
									<p>회원가입문의</p>
									<h4>요청 0  대기 0</h4>	
								</div>
							</div>
						</div>
						<!-- end col-3 -->
						<!-- begin col-3 -->
						<div class="col-xl-2 col-md-4">
							<div class="widget widget-stats panel-widget">
								<div class="stats-icon icon-red"><i class="fas fa-arrow-up"></i></div>
								<div class="stats-info">
									<p>입금문의</p>
									<h4>요청 0  대기 0</h4>	
								</div>
							</div>
						</div>
						<!-- end col-3 -->
						<!-- begin col-3 -->
						<div class="col-xl-2 col-md-4">
							<div class="widget widget-stats panel-widget">
								<div class="stats-icon icon-blue"><i class="fas fa-arrow-down"></i></div>
								<div class="stats-info">
									<p>출금문의</p>
									<h4>요청 0  대기 0</h4>	
								</div>
							</div>
						</div>
						<!-- end col-3 -->
						<!-- begin col-3 -->
						<div class="col-xl-2 col-md-4">
							<div class="widget widget-stats panel-widget">
								<div class="stats-icon icon-yellow"><i class="fas fa-comments"></i></div>
								<div class="stats-info">
									<p>고객센타</p>
									<h4>요청 0  대기 0</h4>	
								</div>
							</div>
						</div>
						<!-- end col-3 -->
						<!-- begin col-3 -->
						<div class="col-xl-2 col-md-4">
							<div class="widget widget-stats panel-widget">
								<div class="stats-icon icon-green">P</div>
								<div class="stats-info">
									<p>고객센타</p>
									<h4>요청 0  대기 0</h4>	
								</div>
							</div>
						</div>
						<!-- end col-3 -->
						<div class="col-xl-2 col-md-4">
							<div class="user-container">
								<div class="image image-icon bg-black text-grey-darker">
									<i class="fa fa-user"></i>
								</div>
								<span class="name">admin</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			

			<div class="row">
				<div class="col-xl-8 col-md-12">
					<div class="row">
						<div class="col-xl-2 col-md-3">
							<div class="small-panel">
								<span class="title">보유금</span>
								<span class="text">1,000,000,000</span>
							</div>
						</div>
						<div class="col-xl-2 col-md-3">
							<div class="small-panel">
								<span class="title">TP</span>
								<span class="text">1,000,000,000</span>
							</div>
						</div>
						<div class="col-xl-2 col-md-3">
							<div class="small-panel">
								<span class="title">GS</span>
								<span class="text">1,000,000,000</span>
							</div>
						</div>
						<div class="col-xl-2 col-md-3">
							<div class="small-panel">
								<span class="title">SD</span>
								<span class="text">1,000,000,000</span>
							</div>
						</div>
						<div class="col-xl-2 col-md-3">
							<div class="small-panel bg-green">
								<span class="title">User</span>
								<span class="text">1,000,000,000</span>
							</div>
						</div>
						<div class="col-xl-2 col-md-3">
							<div class="small-panel bg-green">
								<span class="title">Roll</span>
								<span class="text">1,000,000,000</span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-1 col-md-6"></div>
				<div class="col-xl-3 col-md-6">
					<div class="row">
						<div class="col-xl-6 col-md-6">
							<div class="lang-selector">
								<div class="label">Lang</div>
								<div class="small-panel flex-1">
									<b-dropdown menu-class="navbar-language" class="btn-no-back w-100" no-caret>
										<template slot="button-content">
											<span class="flag-icon flag-icon-us mr-1" title="us"></span>
											<span class="name mr-1">EN</span> <b class="caret"></b>
										</template>
										<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-us" title="us"></span> English</b-dropdown-item>
										<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</b-dropdown-item>
										<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</b-dropdown-item>
										<b-dropdown-item href="javascript:;"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</b-dropdown-item>
										<b-dropdown-divider class="m-b-0"></b-dropdown-divider>
										<b-dropdown-item href="javascript:;" class="text-center">more options</b-dropdown-item>
									</b-dropdown>
								</div>
							</div>
						</div>
						<div class="col-xl-6 col-md-6">
							<div class="small-panel">
								<span class="title">Time 2022-07-03 21:32</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end #header -->
	</div>
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'
// import HeaderMegaMenu from './HeaderMegaMenu.vue'

export default {
  name: 'Header',
	components: {
		// HeaderMegaMenu
	},
  data() {
		return {
			pageOptions: PageOptions
		}
  },
	methods: {
		toggleMobileSidebar() {
			this.pageOptions.pageMobileSidebarToggled = !this.pageOptions.pageMobileSidebarToggled;
		},
		toggleMobileRightSidebar() {
			this.pageOptions.pageMobileRightSidebarToggled = !this.pageOptions.pageMobileRightSidebarToggled;
		},
		toggleMobileTopMenu() {
			this.pageOptions.pageMobileTopMenu = !this.pageOptions.pageMobileTopMenu;
		},
		toggleMobileMegaMenu() {
			this.pageOptions.pageMobileMegaMenu = !this.pageOptions.pageMobileMegaMenu;
		},
		toggleRightSidebar() {
			this.pageOptions.pageRightSidebarToggled = !this.pageOptions.pageRightSidebarToggled;
		},
		toggleRightSidebarCollapsed() {
			this.pageOptions.pageRightSidebarCollapsed = !this.pageOptions.pageRightSidebarCollapsed;
		},
		checkForm: function(e) {
			e.preventDefault();
			this.$router.push({ path: '/extra/search' })
		}
	}
}
</script>
