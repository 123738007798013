<script>
import Dashboard from '../pages/Dashboard.vue'
import AgentManagement from '../pages/Agent/AgentManagement.vue'

const routes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/agent/agent-management', component: AgentManagement },
]

export default routes;
</script>
