<script>
const sidebarMenu = [
  { path: '/dashboard', icon: 'far fa-chart-bar', title: '대시보드' },
  { path: '/admin-info', icon: 'fas fa-home', title: '관리자정보'},
  { path: '/agent', icon: 'fa fa-align-left', title: '에이전트 관리',
    children: [
      { path: '/agent/agent-management', title: '에이전트 관리'},
      { path: '/agent/work-details', title: '일처리내역' },
      { path: '/agent/rolling-details', title: '롤링금전환내역' },
    ]
  },
  { path: '/users', icon: 'fa fa-users', title: '회원관리',
    children: [
      { path: '/users/list', title: '회원리스트' },
      { path: '/users/rolling-details', title: '롤링금전환내역' },
      { path: '/users/money-details', title: '머니이동내역' },
    ]
  },
  { path: '/report', icon: 'fas fa-calendar-alt', title: '리포트',
    children: [
      { path: '/report/daily', title: '일자별' },
      { path: '/report/thirdparty', title: '서드파티별' },
      { path: '/report/shop', title: '매장별' },
      { path: '/report/users', title: '회원별' },
    ]
  },
  { path: '/game-details', icon: 'fas fa-list', title: '게임상세내역',
    children: [
      { path: '/game-details/all', title: '전체' },
      { path: '/game-details/slot', title: '슬롯' },
      { path: '/game-details/casino', title: '카지노' }
    ]
  },
  { path: '/analytics', icon: 'fas fa-dollar-sign', title: '정산관리' },
  { path: '/money-io', icon: 'fas fa-shopping-cart', title: '입출금관리',
    children: [
      { path: '/analytics/all', title: '입출금관리' },
      { path: '/analytics/input', title: '입금관리' },
      { path: '/analytics/output', title: '출금관리' },
    ]
  },
  { path: '/customer-center', icon: 'fa fa-comments', title: '고객문의'},
  { path: '/noticeboard', icon: 'fa fa-file-alt', title: '게시판'},
  { path: '/game-settings', icon: 'fas fa-briefcase', title: '게임설정'},
  { path: '/system-log', icon: 'fas fa-map-marker-alt', title: '시스템로그'},
  { path: '/logout', icon: 'fas fa-arrow-left', title: '로그아웃'},
]

export default sidebarMenu;
</script>
